body {
    width: 100vw;
    margin: 0 !important;
    padding: 0 !important;
}

@media (min-width: 601px){
    body {
        background-color: white !important;
    }
}
@media (max-width: 600px){
    body {
    }
}


.MuiInputBase-adornedEnd::before{
    border-bottom-color: transparent;
    border: none;
}
